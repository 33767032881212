import { action, makeObservable, runInAction } from 'mobx';

import { shortCodeToken, toQueryString } from '@feathr/hooks';
import type {
  Attributes,
  ICollectionStore,
  IMetadata,
  IRachisMessage,
  IWretchResponseValid,
} from '@feathr/rachis';
import { BulkCollection, isWretchError, wretch } from '@feathr/rachis';

import type { TAttributionModel } from '../stats';
import type { ITemplate } from '../templates';
import { TemplateClass } from '../templates';
import { AffinityCampaign } from './affinity';
import { AutoPinpointEmailCampaign } from './auto_pinpoint_email';
import type { AutoPinpointEmailBaseCampaign } from './auto_pinpoint_email_base';
import type { Campaign } from './campaign';
import { BaseCampaign } from './campaign';
import { ConversationCampaign } from './conversation';
import type { DisplayCampaign } from './display';
import { DripCampaign } from './drip';
import { DripStepCampaign } from './drip_step';
import type { EmailBaseCampaign } from './email_base';
import { EmailListCampaign } from './email_list';
import { EmailListFacebookCampaign } from './email_list_facebook';
import { FacebookCampaign } from './facebook';
import { GoogleAdsSmartCampaign } from './google_ads_smart';
import type { TLandingPageCampaign } from './landing_page';
import { LandingPageCampaign } from './landing_page';
import { LookalikeCampaign } from './lookalike';
import { MobileGeoFenceRetargetingCampaign } from './mobile_geo_fence_retargeting';
import { MobileGeoFencingCampaign } from './mobile_geo_fencing';
import { PinpointEmailCampaign } from './pinpoint_email';
import type { PinpointEmailBaseCampaign } from './pinpoint_email_base';
import { PinpointPartnerMessage } from './pinpoint_partner_message';
import { ReferralCampaign } from './referral';
import { SearchCampaign } from './search';
import { SeedSegmentCampaign } from './seed_segment';
import { SegmentCampaign } from './segment';
import { SmartPinpointEmailCampaign } from './smart_pinpoint_email';
import type { SmartPinpointEmailBaseCampaign } from './smart_pinpoint_email_base';
import { TrackedLinkCampaign } from './tracked_link';
import type { ICampaignAttributes, TCampaignGroup } from './types';
import { CampaignClass } from './types';

import defaultPageTemplate from './defaultPageTemplate.json';

const {
  Affinity,
  AutoPinpointEmail,
  Conversation,
  Drip,
  DripStep,
  EmailList,
  EmailListFacebook,
  Facebook,
  GoogleAdsSmart,
  LandingPage,
  Lookalike,
  MobileGeoFenceRetargeting,
  MobileGeoFencing,
  PinpointEmail,
  PinpointPartnerMessage: PartnerMessage,
  Referral,
  Search,
  SeedSegment,
  Segment,
  SmartPinpointEmail,
  TrackedLink,
} = CampaignClass;

export const CampaignGroupMap: Record<TCampaignGroup, CampaignClass[]> = {
  ads: [
    Affinity,
    EmailList,
    EmailListFacebook,
    Facebook,
    GoogleAdsSmart,
    Lookalike,
    MobileGeoFenceRetargeting,
    MobileGeoFencing,
    Search,
    SeedSegment,
    Segment,
  ],
  all: [
    Affinity,
    AutoPinpointEmail,
    Conversation,
    Drip,
    EmailList,
    EmailListFacebook,
    Facebook,
    GoogleAdsSmart,
    LandingPage,
    Lookalike,
    MobileGeoFenceRetargeting,
    MobileGeoFencing,
    PinpointEmail,
    Referral,
    Search,
    SeedSegment,
    Segment,
    SmartPinpointEmail,
    TrackedLink,
  ],
  email: [AutoPinpointEmail, PinpointEmail, SmartPinpointEmail, Drip],
  'google-ads': [GoogleAdsSmart],
  monetization: [
    Affinity,
    EmailList,
    Lookalike,
    MobileGeoFenceRetargeting,
    MobileGeoFencing,
    Search,
    Segment,
    SeedSegment,
  ],
  other: [Referral, Conversation, TrackedLink, LandingPage],
};

export function disambiguateCampaign(attributes: any) {
  switch (attributes._cls) {
    case Affinity:
      return new AffinityCampaign(attributes);

    case AutoPinpointEmail:
      return new AutoPinpointEmailCampaign(attributes);

    case Conversation:
      return new ConversationCampaign(attributes);

    case Drip:
      return new DripCampaign(attributes);

    case DripStep:
      return new DripStepCampaign(attributes);

    case EmailList:
      return new EmailListCampaign(attributes);

    case EmailListFacebook:
      return new EmailListFacebookCampaign(attributes);

    case Facebook:
      return new FacebookCampaign(attributes);

    case GoogleAdsSmart:
      return new GoogleAdsSmartCampaign(attributes);

    case LandingPage:
      return new LandingPageCampaign(attributes);

    case Lookalike:
      return new LookalikeCampaign(attributes);

    case MobileGeoFencing:
      return new MobileGeoFencingCampaign(attributes);

    case MobileGeoFenceRetargeting:
      return new MobileGeoFenceRetargetingCampaign(attributes);

    case PartnerMessage:
      return new PinpointPartnerMessage(attributes);

    case PinpointEmail:
      return new PinpointEmailCampaign(attributes);

    case Referral:
      return new ReferralCampaign(attributes);

    case Search:
      return new SearchCampaign(attributes);

    case SeedSegment:
      return new SeedSegmentCampaign(attributes);

    case Segment:
      return new SegmentCampaign(attributes);

    case SmartPinpointEmail:
      return new SmartPinpointEmailCampaign(attributes);

    case TrackedLink:
      return new TrackedLinkCampaign(attributes);

    default:
      return new BaseCampaign(attributes);
  }
}

export type TCampaigns =
  | AffinityCampaign
  | AutoPinpointEmailBaseCampaign
  | AutoPinpointEmailCampaign
  | BaseCampaign
  | ConversationCampaign
  | DisplayCampaign
  | DripCampaign
  | DripStepCampaign
  | EmailBaseCampaign
  | EmailListCampaign
  | EmailListFacebookCampaign
  | FacebookCampaign
  | LandingPageCampaign
  | LookalikeCampaign
  | MobileGeoFenceRetargetingCampaign
  | MobileGeoFencingCampaign
  | PinpointEmailBaseCampaign
  | PinpointEmailCampaign
  | ReferralCampaign
  | SearchCampaign
  | SeedSegmentCampaign
  | SegmentCampaign
  | SmartPinpointEmailBaseCampaign
  | SmartPinpointEmailCampaign
  | TrackedLinkCampaign;

export interface ICampaignPartnerStats extends Record<string, unknown> {
  error: string;
  p_id: string;
  views: number;
  clicks: number;
  conversions: number;
  leads: number;
}

export interface ICampaignPartnerStatsMetadata extends IMetadata {
  is_partial: boolean;
}

type TCollectionUrlVariant =
  | 'export'
  | 'export-all'
  | 'google.suggest.budget'
  | 'google.suggest.keyword'
  | 'google.suggest.ad'
  | 'google.location';

export interface IExportCampaignsProps {
  attribution_model?: TAttributionModel;
  columns: string[];
  email: string;
  filename?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: Record<string, any>;
  sort?: string[];
}

export class Campaigns<
  Model extends Campaign<ICampaignAttributes> = Campaign<ICampaignAttributes>,
> extends BulkCollection<Model> {
  constructor(initialModels: Array<Partial<Attributes<Model>>>, store: ICollectionStore = {}) {
    super(initialModels, store);

    makeObservable(this);
  }

  public getModel(attributes: Partial<Attributes<Model>>): Model {
    return disambiguateCampaign(attributes) as Model;
  }

  public override url(variant: Exclude<TCollectionUrlVariant, 'export-all'>, value: string): string;
  public override url(variant: 'export-all'): string;
  public override url(): string;
  public override url(variant?: TCollectionUrlVariant, value?: string): string {
    const root = super.url(variant, value);
    const googlePath = `${this.getHostname()}integrations/google-ads`;
    const suggestPath = `${googlePath}/campaign/${value}/suggest`;

    switch (variant) {
      case 'export-all':
        return `${this.getHostname()}exports/campaigns`;

      case 'export':
        return `${root}${value}/export`;

      case 'google.suggest.budget':
        return `${suggestPath}/budget`;

      case 'google.suggest.keyword':
        return `${suggestPath}/keyword_theme`;

      case 'google.suggest.ad':
        return `${suggestPath}/ad`;

      case 'google.location':
        return `${googlePath}/location?term=${value}`;

      default:
        return `${this.getHostname()}flight_campaigns/`;
    }
  }

  public getClassName(): string {
    return 'campaigns';
  }

  @action
  public async publish(model: Model): Promise<Model> {
    model.isUpdating = true;
    const response = await wretch<ICampaignAttributes>(`${this.url()}${model.id}/publish`, {
      method: 'POST',
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      runInAction(() => {
        model.isUpdating = false;
        model.isErrored = true;
        model.error = response.error;
      });
      return model;
    }
    return this.processJSONResponse(response);
  }

  public async getPartnerStats(
    id: string,
    start: string,
    end: string,
    attributionModel: TAttributionModel,
    sortParams: Array<{
      id: string;
      desc?: true;
    }>,
    paginationParams: { page: number; page_size: number },
  ): Promise<IWretchResponseValid<ICampaignPartnerStats[], ICampaignPartnerStatsMetadata>> {
    const params = {
      start,
      end,
      attribution_model: attributionModel,
      pagination: paginationParams,
      ordering: sortParams.map((s) => (s.desc ? `-${s.id}` : s.id)),
    };
    const search = toQueryString(this.parseJSONParams(params));
    const response = await wretch<ICampaignPartnerStats[], ICampaignPartnerStatsMetadata>(
      `${this.getHostname()}campaigns/${id}/partner_stats?${search}`,
      {
        method: 'GET',
        headers: this.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response;
  }

  public async exportPartnerStats(
    id: string,
    start: string,
    end: string,
    attributionModel: TAttributionModel,
    sortParams: Array<{
      id: string;
      desc?: true;
    }>,
    paginationParams: { page: number; page_size: number },
    email: string,
  ): Promise<IRachisMessage> {
    const params = {
      partner_stats: '1',
      start,
      end,
      email,
      attribution_model: attributionModel,
      pagination: paginationParams,
      ordering: sortParams.map((s) => (s.desc ? `-${s.id}` : s.id)),
    };
    const search = toQueryString(this.parseJSONParams(params));
    const response = await wretch<IRachisMessage>(
      `${this.getHostname()}campaigns/${id}/export?${search}`,
      {
        method: 'GET',
        headers: this.getHeaders(),
      },
    );

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async exportCampaigns({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    attribution_model,
    columns,
    email,
    filename,
    filters,
    sort,
  }: IExportCampaignsProps): Promise<IRachisMessage> {
    const response = await wretch<IRachisMessage>(this.url('export-all'), {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({ attribution_model, columns, email, filename, filters, sort }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async addCampaign(
    type: CampaignClass,
    eventId: string,
    overrides: Partial<ICampaignAttributes> = {},
  ): Promise<Model> {
    const campaignAttrs: Partial<ICampaignAttributes> = {
      _cls: type,
      event: eventId,
      parent: eventId,
    };

    if (type === LandingPage) {
      (campaignAttrs as TLandingPageCampaign).template = {
        ...defaultPageTemplate,
        _cls: TemplateClass.Page,
        short_code: shortCodeToken(12),
      } as unknown as ITemplate;
    } else if (type === TrackedLink) {
      campaignAttrs.domain_id = '000000000000000000000000';
    }
    const campaign = this.create({ ...campaignAttrs, ...overrides } as Partial<Attributes<Model>>);
    return this.add(campaign, { validate: false });
  }
}
